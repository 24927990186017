:root {
    --red: #e10707;
    --hover-color: #cc0000;
    --text-color: #333333;
    --light-grey: #f8f8f8;

    --approved-color: #00da0d;
    --declined-color: #cc0000;

    --cancel-color: #eaeaea;
    --cancel-hover: #d9d9d9;
}

.main-container {
    padding-top: 10%;
}

@media screen and (max-width: 992px) {
    .main-container {
        padding-top: 20%;
    }
}

@media screen and (max-width: 600px) {
    .main-container {
        padding-top: 30%;
    }
}

/* Select Dropdowns */
.ReactModal__Content .react-select__control {
    margin-bottom: 15px;
    border-color: var(--red);
    /* Change border color to red */
}

/* Selected Option */
.ReactModal__Content .react-select__single-value {
    color: var(--red);
    /* Change text color of selected option to red */
}

/* Selected Indicator */
.ReactModal__Content .react-select__indicator-separator {
    background-color: var(--red);
    /* Change indicator separator color to red */
}

/* Dropdown Indicator */
.ReactModal__Content .react-select__dropdown-indicator {
    color: var(--red);
    /* Change dropdown indicator color to red */
}

.ReactModal__Content:focus-visible {
    outline-color: var(--hover-color) !important;
}

.css-b62m3t-container:focus-visible {
    outline-style: solid;
}