/* .profile-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-container {
    padding: 40px 120px;
    background: var(--light-grey);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.profile-container .profile-details {
    margin-top: 20px;
}

@media screen and (max-width: 480px) {
    .profile-container {
        width: 90%;
        padding: 40px;
    }
}
 */

.profile-main-container {
    margin-bottom: 5%;
}

.profile-main-container .title-container {
    margin-bottom: 20px;
    text-align: center;
}

.profile-container {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: center;
    width: 85%;
    margin: auto;
}

.sidebar {
    width: 20%;
    /* padding: 20px; */
}

.sidebar button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    background: transparent;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: var(--text-color);
    font-weight: 500;
}

.sidebar button:hover {
    color: var(--hover-color);
}

.sidebar .active-tab {
    background: var(--red);
    color: #fff;
    font-weight: 600;
}

.sidebar .active-tab:hover {
    color: #fff;
}

.profile-container .right-container {
    width: 75%;
    min-height: 400px;
    background: var(--light-grey);
    border-radius: 10px;
    padding: 26px;
}

.profile {
    padding: 20px;
}

.profile h2 {
    margin-bottom: 20px;
}

.profile .active-tab {}

.profile-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 20px;
}

.profile-info div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.profile-info label {
    min-width: 100px;
    margin-right: 10px;
    font-weight: 700;

}

.profile-info input[type="text"], .profile-info input[type="email"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.profile-info span {
    padding: 8px 0;
}

.edit-button, .edit-buttons {
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-button,
.edit-buttons button {
    padding: 10px 20px;
    margin: 0 4px;
    background-color: var(--red);
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    
}

.edit-button:hover,
.edit-buttons button:hover {
    background: var(--hover-color);
}

.password-fields {
    max-width: 280px;
    min-width: 180px;
    margin: 26px auto;
    display: grid;
}

.password-fields .change-password-btn {
    background: var(--red);
    padding: 10px 20px;
    border-radius: 8px;
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: 500;
    margin: 10px auto;

}

.password-fields .change-password-btn:hover {
    background: var(--hover-color);

}

@media screen and (max-width: 768px) {
    .profile-container {
        flex-direction: column;
    }

    .right-container {
        width: 100% !important;
    }

    .profile-info {
        grid-template-columns: none;
    }

    .sidebar {
        width: 100%;
    }

    .profile {
        width: 100%;
    }
}