.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(54, 54, 54, 0.4);
    display: none; /* Initially hide loader */
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loader-overlay.active {
    display: flex; /* Show loader when active */
  }
  
  .loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid var(--hover-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  .small-loader-container {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
  }
  
  .small-loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid var(--red);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  