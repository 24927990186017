.calendar-container {
    height: 680px;
}

.calender-title {
    font-weight: 600;
    text-align: center;
    font-size: 38px;
    margin-bottom: 20px;
}

.rbc-calendar {
    width: 90%;
    padding: 30px;
    border-radius: 10px;
    margin: 10px auto;
    background: var(--light-grey);
}

.rbc-toolbar button {
    background: var(--red) !important;
    color: #fff !important;
    border: 1px solid var(--text-color) !important;
    padding: 9px 16px !important;
    font-size: 13px;
    font-weight: 600 !important;
    width: auto;
    border-radius: 8px;
    /* margin: 1px !important; */
}

.rbc-toolbar button:hover {
    background: #e23d3d !important;
}

.rbc-toolbar .rbc-active {
    background-color: var(--hover-color) !important;
}

.rbc-show-more:hover,
.rbc-show-more:focus {
    color: #ffffff;
}

.rbc-button-link {
    padding: 6px 9px !important;
    border-radius: 100px;
    width: auto;
}

.rbc-button-link:hover {
    color: #ffffff;
    background-color: var(--hover-color);
}

.rbc-toolbar .rbc-toolbar-label {
    font-weight: 700;
    font-size: 22px;
}

.rbc-agenda-table tr {
    background: var(--light-grey) !important;
}

.rbc-agenda-table tr:hover {
    background: #f1f1f1 !important;
}

.rbc-event-content {
    font-size: 13px;
    font-weight: 600;
}

.rbc-day-bg:hover {
    background-color: #ececec;
}

.rbc-today {
    background-color: #e23d3d11 !important;
}

.rbc-today:hover {
    background-color: #e23d3d22 !important;
}

.rbc-show-more {
    color: var(--red) !important;
    padding: 4px;
    font-size: 13px !important;
}

.rbc-show-more:hover {
    background: var(--red);
    color: #fff !important;
}


/* 
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
    --arrow-size: 5px;
    position: relative;
    z-index: 10;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    left: 50%;
    bottom: calc(100% + var(--arrow-size));
    pointer-events: none;
    transition: 0.2s;
    will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
    content: attr(data-tooltip);
    padding: 10px 18px;
    min-width: 50px;
    max-width: 300px;
    width: max-content;
    width: -moz-max-content;
    border-radius: 6px;
    font-size: 14px;
    background-color: rgba(59, 72, 80, 0.9);
    background-image: linear-gradient(30deg,
            rgba(59, 72, 80, 0.44),
            rgba(59, 68, 75, 0.44),
            rgba(60, 82, 88, 0.44));
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
    color: #fff;
    text-align: center;
    white-space: pre-wrap;
    transform: translate(-50%, calc(0px - var(--arrow-size))) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
    content: '';
    border-style: solid;
    border-width: var(--arrow-size) var(--arrow-size) 0px var(--arrow-size);
    /* CSS triangle */
    border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
    transition-duration: 0s;
    /* If the mouse leaves the element, 
                                the transition effects for the 
                                tooltip arrow are "turned off" */
    transform-origin: top;
    /* Orientation setting for the
                                slide-down effect */
    transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
}

/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
    transition-delay: 0.3s;
    transform: translate(-50%, calc(0px - var(--arrow-size))) scale(1);
}

/* 
    Arrow slide down effect only on mouseenter (NOT on mouseleave)
  */
[data-tooltip]:hover:after {
    transition-delay: 0.5s;
    /* Starting after the grow effect */
    transition-duration: 0.2s;
    transform: translateX(-50%) scaleY(1);
}

/*
    That's it for the basic tooltip.
  
    If you want some adjustability
    here are some orientation settings you can use:
  */

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
    left: auto;
    right: calc(100% + var(--arrow-size));
    bottom: 50%;
}

/* Tooltip */
[data-tooltip-location="left"]:before {
    transform: translate(calc(0px - var(--arrow-size)), 50%) scale(0.5);
}

[data-tooltip-location="left"]:hover:before {
    transform: translate(calc(0px - var(--arrow-size)), 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
    border-width: var(--arrow-size) 0px var(--arrow-size) var(--arrow-size);
    border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
    transform-origin: left;
    transform: translateY(50%) scaleX(0);
}

[data-tooltip-location="left"]:hover:after {
    transform: translateY(50%) scaleX(1);
}



/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
    left: calc(100% + var(--arrow-size));
    bottom: 50%;
}

[data-tooltip-location="right"]:before {
    transform: translate(var(--arrow-size), 50%) scale(0.5);
}

[data-tooltip-location="right"]:hover:before {
    transform: translate(var(--arrow-size), 50%) scale(1);
}

[data-tooltip-location="right"]:after {
    border-width: var(--arrow-size) var(--arrow-size) var(--arrow-size) 0px;
    border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
    transform-origin: right;
    transform: translateY(50%) scaleX(0);
}

[data-tooltip-location="right"]:hover:after {
    transform: translateY(50%) scaleX(1);
}



/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
    top: calc(100% + var(--arrow-size));
    bottom: auto;
}

[data-tooltip-location="bottom"]:before {
    transform: translate(-50%, var(--arrow-size)) scale(0.5);
}

[data-tooltip-location="bottom"]:hover:before {
    transform: translate(-50%, var(--arrow-size)) scale(1);
}

[data-tooltip-location="bottom"]:after {
    border-width: 0px var(--arrow-size) var(--arrow-size) var(--arrow-size);
    border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
    transform-origin: bottom;
}

@keyframes moveFocus {
    0% {
        background-position: 0% 100%
    }

    100% {
        background-position: 100% 0%
    }
}

@media (max-height: 450px) {}

@media (max-width: 800px) {
    html {
        font-size: 0.9em;
    }
}

/* Thumbnail settings */
@media (max-width: 750px) {

    [data-tooltip]:after {
        bottom: calc(100% + 3px);
    }

    [data-tooltip]:after {
        border-width: 7px 7px 0px 7px;
    }
}



@media (max-width: 576px) {

    .calendar-container {
        height: 480px;
    }

    .rbc-calendar {
        width: 95%;
        padding: 16px !important;
    }

    .calender-title {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .rbc-toolbar button {
        font-size: 12px;
        padding: 8px 16px !important;
        /* margin: 0px 2px !important; */
    }

    .rbc-button-link {
        padding: 3px 0px !important
    }

    .rbc-toolbar-label {
        margin: 8px 0;
    }

    .rbc-month-view {
        font-size: 11px;
    }
}

@media (max-width: 300px) {
    .rbc-toolbar button {
        font-size: 10px;
        padding: 8px 12px !important;
    }
}