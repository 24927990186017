.student-dashboard-container {}

.student-dashboard-container .coach-head-btns {
    width: 90%;
    margin: auto;
    margin-bottom: 24px;
    text-align: right;
}

.student-dashboard-container .coach-head-btns button {

    background: var(--red);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    margin: 0 4px;
    font-weight: 500;

}

.student-dashboard-container .coach-head-btns button:hover {
    background: var(--hover-color);
}

@media screen and (max-width:480px) {
    .student-dashboard-container .coach-head-btns {
        text-align: center;
    }
}