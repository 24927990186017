.navbar {
    background: #fff !important;
    padding: 1% 8% !important;
    border-bottom: 1px solid lightgrey;
    position: fixed !important;
    width: 100%;
    z-index: 100 !important;
    justify-content: space-between !important;
}

.navbar-brand {
    /* margin-left: 8%; */
}

.navbar .btn-container {}

.navbar .nav-link {
    font-weight: 600;
    width: fit-content;
    padding: 10px 20px !important;
    color: var(--text-color) !important;
}

.navbar .nav-link:hover {
    color: var(--hover-color) !important;
}

.navbar .navbar-toggler {
    width: auto;
}


.navbar .navbar-collapse {
    justify-content: end;
}

@media screen and (max-width: 992px) {
    .navbar-nav {
        margin-bottom: 10px !important;
    }

    .navbar .nav-link {
        /* padding: 10px 0 !important; */
    }

    .navbar .login-nav-link {
        padding: 10px 0 !important;
    }

    .navbar .navbar-toggler {
        border-radius: 100px !important;
    }
}

@media screen and (max-width: 520px) {
    .navbar-brand img {
        height: 60px;
    }

}