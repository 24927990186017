.bookings-container {
    padding: 5%;
}

.booking-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px !important;
    max-width: 720px;
    margin: auto;
}

.booking-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.booking-details {
    flex: 1;
}

.booking-details .booking-id {
    margin-bottom: 10px;
}

.booking-details p {
    margin-bottom: 4px;
}

.booking-details .comments {
    background: #ffe8eb;
    color: #910000;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 10px;
    border: 0.1px solid;
}

.booking-id {
    font-weight: bold;
}

.student-info {
    /* display: flex; */
    align-items: center;
    width: 30%;
}

.student-info .cancel-request-btn {
    background-color: var(--red);
    color: #fff;
    font-weight: 500;
    padding: 8px 18px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    display: block;
    margin: auto;
}

.student-info .cancel-request-btn:hover {
    background-color: var(--hover-color);
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.student-name {
    font-weight: bold;
}

.filters-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filters-container .filter-item {
    width: 220px;
}

.filter-main-container .filter-btn-container {
    text-align: center;
    margin: 10px 0;
}

.filter-main-container .filter-btn-container .btn {
    margin: 6px;
    font-size: 14px;
    font-weight: 500;
}

.filter-main-container .filter-btn-container .apply-filter {
    background: var(--red);
    color: #fff;
}

.filter-main-container .filter-btn-container .apply-filter:hover {
    background: var(--hover-color);
}

.filter-main-container .filter-btn-container .clear-filter {
    background: var(--cancel-color);
}

.filter-main-container .filter-btn-container .clear-filter:hover {
    background: var(--cancel-hover);
}

input[type="date"] {
    padding: 6px 10px !important;
    border: 1px solid lightgray;
    border-radius: 4px;
    color: var(--text-color);
    width: 90%;
}

input[type="date"]:focus-visible {
    outline: var(--hover-color) auto 1px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .booking-card {
        padding: 15px;
    }

    .avatar {
        width: 40px;
        height: 40px;
    }

    .filters-container {
        display: grid;
    }

    .filters-container .filter-item {
        width: 220px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 6px 0;
    }

    .student-info {
        flex-direction: column;
    }

    .student-info .image-container {
        margin-right: 0 !important;
        margin-bottom: 10px;
    }
}