.request-to-approve {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    min-width: 300px;
    margin-right: 12px;
    border: 2px solid var(--red);
}

.request-to-approve .request-details .fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 600;
}

.request-to-approve .request-details p {
    padding: 8px 18px;
    margin: 0;
    border-radius: 24px;
    background: var(--light-grey);
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
}

.request-to-approve .request-btns-container {
    display: flex;
    margin-top: 10px;
}

.request-to-approve .approve-button,
.request-to-approve .decline-button {
    background: var(--red);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
}

.request-to-approve .approve-button {
    margin-right: 4px;
}

.request-to-approve .approve-button:hover {
    background: var(--hover-color);
}

.request-to-approve .decline-button {
    margin-left: 4px;
    background: #f0f0f0;
    color: var(--text-color);
}

.request-to-approve .decline-button:hover {
    background: #dadada;
}

@media screen and (max-width:480px) {

    .request-to-approve {
        min-width: 92%;
    }
}