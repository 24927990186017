.coach-dashboard-container {
    display: block;
    justify-content: center;
    padding: 10px 20px;
}

.coach-dashboard-container .coach-head-btns {
    width: 90%;
    margin: auto;
    margin-bottom: 24px;
    text-align: right;
}

.coach-dashboard-container .coach-head-btns button {

    background: var(--red);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    margin: 0 4px;
    font-weight: 500;

}

.coach-dashboard-container .coach-head-btns button:hover {
    background: var(--hover-color);
}

.coach-dashboard-container .requests-container {
    width: 90%;
    margin: auto;
    padding: 20px;
    background-color: var(--light-grey);
    border-radius: 10px;
    margin-bottom: 40px;
}

.coach-dashboard-container .requests-container .title {
    text-align: center;
}

.coach-dashboard-container .requests-container .requests-to-approve {
    display: flex;
    overflow-x: auto;
    padding-bottom: 6px;
}

@media screen and (max-width:480px) {

    .coach-dashboard-container .requests-container .requests-to-approve {
        padding-bottom: 10px;
    }

    .coach-dashboard-container .requests-container .title {
        text-align: center;
    }

    .coach-dashboard-container .requests-container {
        width: 98%;
        margin: auto;
        padding: 0;
    }

    .coach-dashboard-container .coach-head-btns {
        text-align: center;
    }
}