/* Modal Container */
.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5) !important;
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.ReactModal__Content {
    position: relative;
    background-color: #fff;
    border-radius: 8px !important;
    padding: 40px !important;
    max-width: 80%;
    min-width: 400px !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1) !important;
    inset: unset !important;
}

/* Modal Title */
.ReactModal__Content h2 {
    margin-bottom: 20px;
    color: #333;
}

/* Form Fields */
.ReactModal__Content label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #555;
}

/* Select Dropdowns */
.ReactModal__Content .react-select__control {
    margin-bottom: 15px;
}

/* Submit Button */
.ReactModal__Content button {
    background-color: var(--red);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px auto;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 0;
}

.ReactModal__Content button:hover {
    background-color: var(--hover-color);
}

.select-field {
    margin-bottom: 20px;
}


.modal-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-btn-container .cancel-button {
    background-color: #f1f0f0;
    color: #555;
    margin-right: 10px;
}

.modal-btn-container .cancel-button:hover {
    background-color: #dadada;
}

.close-comment-modal:hover {
    background: var(--cancel-hover) !important;
}

/* Define CSS for modal animation */
.AppointmentModal-enter {
    opacity: 0;
}

.AppointmentModal-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.AppointmentModal-exit {
    opacity: 1;
}

.AppointmentModal-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
}

.modal-body p {
    margin: 10px 0;
    text-align: center;
}

@media (max-width: 768px) {
    .ReactModal__Content {
        padding: 30px !important;
        min-width: 280px !important;
    }
}

@media (max-width: 576px) {
    .ReactModal__Content {
        max-width: 90%;
    }

    .modal-btn-container {
        font-size: 12px;
    }

    .ReactModal__Content button {
        padding: 10px !important;
    }
}

.ReactModal__Content .react-select__control:focus-visible {
    border-color: var(--red);
    /* Change border color to red */
    box-shadow: 0 0 0 2px var(--red);
    /* Add red box shadow for emphasis */
}