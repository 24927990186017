.custom-date-picker-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 22px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-date-picker-container .date-picker-container {
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.custom-date-picker-container label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.custom-date-picker-container input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.custom-date-picker-container button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    background-color: var(--red);
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
    margin: auto;
    max-width: 220px;
}

.custom-date-picker-container button:hover {
    background-color: var(--hover-color);
}

@media screen and (max-width: 768px) {
    .custom-date-picker-container {
        max-width: 300px;
    }

    .custom-date-picker-container .date-picker-container {

        display: block;
    }
}