.speciality-table .btn-container .edit-btn,
.speciality-table .btn-container .delete-btn {
    padding: 10px 20px;
    margin: 0 4px;
    background-color: var(--red);
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.speciality-table .btn-container .edit-btn {
    background: transparent;
    color: var(--text-color);
}

.speciality-table .btn-container .edit-btn:hover {
    color: var(--hover-color);
}

.speciality-table .btn-container .delete-btn:hover {
    background: var(--hover-color);
}