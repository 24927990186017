.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 0;
}

.login-container .title-container {
    margin-bottom: 20px;
}

.form-container {
    padding: 40px;
    background: #f8f8f8;
    border-radius: 10px;
    width: 420px;
}

.field {
    margin-bottom: 10px;
    position: relative;
}

input[type=text],
input[type=email],
input[type=number],
input[type=password],
textarea {
    width: 100%;
    padding: 8px 10px;
    margin: 8px 0;
    font-size: 14px;
    color: var(--text-color);
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid grey;
    font-weight: 500;
    border: 0.5px solid var(--text-color);
}

input[type=password] {
    position: relative;
}

.view-password-icon-container {
    width: 20px;
    position: absolute;
    bottom: 16px;
    right: 8px;
}

.view-password-icon-container .view-password-icon {
    font-size: 13px;
}

.view-password-icon-container .view-password-icon:hover {
    color: var(--hover-color);
    cursor: pointer;
}

input[type=text]:focus-visible,
input[type=email]:focus-visible,
input[type=number]:focus-visible,
input[type=password]:focus-visible,
textarea:focus-visible {
    /* outline: var(--red) auto 1.3px; */
    outline-style: solid;
    outline-color: var(--hover-color);
}

::placeholder {
    font-size: 15px;
    opacity: 1;
    color: gray;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    font-size: 15px;
    color: gray;
}

input[type="checkbox" i] {
    accent-color: var(--red);
    padding: 10px;
    width: 14px;
    height: 14px;
    margin-right: 8px;
}

.field-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-btn-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.login-button {
    background-color: var(--red);
    color: white;
    padding: 10px 20px;
    min-width: 50%;
    border: none;
    border-radius: 6px !important;
    font-weight: 500;
    font-size: 14px;
    margin: auto;
}

.login-button:hover {
    background: var(--hover-color);
    cursor: pointer;
}

.forgot-password-container {
    text-align: center;
}

.forgot-text {
    color: var(--red);
    font-size: 14px;
}

@media screen and (max-width:480px) {
    .form-container {
        width: 90%;
    }
}