.chart-container {
    width: 80%;
    /* Make the chart container 100% width */
    height: auto;
    /* Allow the height to adjust according to aspect ratio */
    margin: auto;
}

@media screen and (max-width: 480px) {
    .chart-container {
        width: 90% !important;
    }
}