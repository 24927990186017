.image-container,
.box-image-container {
    display: grid;
    justify-content: center;
}

.image-container .no-profile,
.box-image-container .no-profile {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    color: #565656;
    position: relative;
}

.box-image-container .no-profile {
    border-radius: 8px;
    width: 320px;
}

.image-container .no-profile input[type="file"],
.box-image-container .no-profile input[type="file"] {
    display: inline;
    opacity: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
}

.image-wrapper {
    position: relative;
}

.image-wrapper .remove-image {
    position: absolute;
    top: 8px;
    right: 9px;
    cursor: pointer;
}

.image-wrapper .remove-image:hover {
    color: #d40000;
}

.image-container img,
.box-image-container img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: contain;
    background: lightgrey;
}

.box-image-container img {
    width: 320px;
    border-radius: 8px;
}

.image-container input[type="file"],
.box-image-container input[type="file"] {
    display: none;
}


.image-container .custom-file-upload,
.box-image-container .custom-file-upload {
    font-size: 15px;
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
    margin: 20px 0;
}

.image-container .custom-file-upload:hover,
.box-image-container .custom-file-upload:hover {
    background: lightgray;
}

@media screen and (max-width: 480px) {
    .box-image-container .no-profile {
        width: 250px !important;
    }

    .box-image-container img {
        width: 250px;
        border-radius: 8px;
    }
}