.stats-container .title-container {}

.stats-container .title-container .title {
    text-align: center;
    font-size: 32px;
}

.stats-container .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 14px 0;
}

.stats-container .btn-container .monthly-btn,
.stats-container .btn-container .yearly-btn {
    width: 100px;
    font-size: 14px;
    margin: 4px;
    padding: 8px 12px;
}

.stats-container .btn-container button {
    border: none;
    background: var(--red);
    font-size: 14px;
    white-space: nowrap;
    color: white;
    height: fit-content;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 16px;
}

.stats-container .btn-container button:hover {
    background: var(--hover-color);
}

.stats-container .select-time-container {
    display: flex;
    flex-wrap: wrap;
    width: 94%;
    margin: auto;
}

.stats-container .select-coach-container,
.stats-container .select-student-container {
    width: 200px;
    margin: auto;
    margin-bottom: 20px;
}


.appointment-list-container {
    padding: 0 60px;
    margin-bottom: 60px;
}

.appintment-list-title {
    text-align: center;
}

.total-container {
    display: flex;
}

.total-classes,
.total-paid-classes {
    padding: 20px;
    background: #ffeeee;
    border-radius: 8px;
    text-align: center;
    margin: 16px 0;
    width: 100%;
    font-weight: 500;
}

.mark-all-btn-container {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.mark-all-paid {
    border: none;
    color: white;
    background: var(--red);
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 500;
    margin: auto;
}

.mark-all-paid:hover {
    background: var(--hover-color);
}

.mark-all-paid:disabled {
    background: rgb(255, 109, 109);
}

.total-classes {
    margin-right: 5px;
}

.total-paid-classes {
    margin-left: 5px;
    background: #dcffde;
}

.total-classes h1,
.total-paid-classes h1 {
    /* text-align: center; */
    margin-bottom: 0;
}

.appointment-list-container th,
.appointment-list-container td {
    white-space: nowrap;
}

.appointment-list-container td .paid-button {
    background: var(--red);
    border: none;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 4px 10px;
}

.appointment-list-container td .paid-button:hover {
    background: var(--hover-color);
}

.stats-by-month-container {
    display: flex;
    padding: 0 60px;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
}

.stats-by-month-title-container {
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: center;
}

.stats-by-month-title-container .stats-by-month-title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

/* .stats-by-month-container .stats-by-month {
    background: #ddd;
    border-radius: 10px;
    padding: 10px;
    min-width: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stats-by-month-container .stats-by-month p {
    font-size: 30px;
    font-weight: 600;
    color: black;
    margin: 0;
} */

.stats-by-month {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 10px;
    width: 180px;
    height: 175px;
    background-color: #ffe0e0;
    /* Dark mode background */
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    cursor: pointer;
    /* Makes it feel clickable */
}

.stats-by-month:hover {
    transform: translateY(-5px);
    /* Subtle hover effect */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    /* Enhanced hover shadow */
}

.stats-by-month p:first-child {
    font-size: 22px;
    font-weight: bold;
    color: #1e1e1e;
    /* Neutral color for the month */
    margin-bottom: 10px;
}

.stats-by-month .appointment-count {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    background-color: var(--hover-color);
    /* Vibrant teal */
    color: #fff;
    font-size: 54px;
    font-weight: bold;
    border-radius: 12px;
    width: 100%;
    text-align: center;
}


@media screen and (max-width: 480px) {
    .appointment-list-container {
        padding: 0 10px;
    }
}